import React, { useState } from 'react'
import Moment from 'moment';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import Pagination from '../Layout/Pagination';
import * as APIs from "../../Data/APIs";
import { useLoader } from "../Layout/Loader";
import { useAuthentication } from "../../Data/Authentication";
import { CustomTooltip } from "../Layout/Tooltip";
import Zoom from '@material-ui/core/Zoom';
import { useModalDialog } from '../Layout/ModalDialogCustomized';
import { FailureModal } from '../Modals/FailureModal';
import { SuccessModal } from '../Modals/SuccessModal';
import DownloadIcon from '@mui/icons-material/Download';
import { Checkbox } from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import GenericTable from '../Layout/GenericTable';
import dataReadyIconSVG from "../../Icons/dataReadyIcon.svg";
import downloadIconSVG from "../../Icons/downloadIcon.svg";
import schedulerIconSVG from "../../Icons/scheduler.svg";
import oeDataReadyDisabled from "../../Icons/oeDataReadyDisabled.svg";
import oeDataReadyEnabled from "../../Icons/oeDataReadyEnabled.svg";

const todayDate = new Date();
todayDate.setHours(0, 0, 0, 0);

const OERequestsTable = ({
    tableRef,
    advancedFiltersClicked,
    quickFiltersClicked,
    oERequestsList,
    setOERequestsList,
    selectedPage,
    pageSize,
    totalCount,
    setSelectedPage,
    setPageSize,
    onSortFieldFilter,
    onSortOrderFilter,
    selectedPageAdv,
    onSetSelectedPageAdv,
    pageSizeAdv,
    onSetPageSizeAdv,
    onSortFieldAdvFilter,
    onSortOrderAdvFilter,
    setSelectedOEs,
    selectedOEs,
    ...props
}) => {

    const { authUser } = useAuthentication();
    const userActions = authUser?.signInUserSession?.idToken?.payload["userActions"]?.slice(0, -1).split(",");
    const dialog = useModalDialog(0);

    const { openLoader, closeLoader } = useLoader();

    const handleSelectAllOEsClick = (value) => (event) => {
        let selectedOEsArray = selectedOEs;
        if (!value || value === "") selectedOEsArray = [];
        else if (selectedOEsArray.indexOf(...value) >= 0) {
            selectedOEsArray.splice(selectedOEsArray.indexOf(...value));
        }
        else {
            selectedOEsArray.length = 0;
            selectedOEsArray.push(...value);
        }
        setSelectedOEs([...selectedOEsArray]);
    }


    const handleOEsSelectionClick = (value) => (event) => {
        let selectedOEsArray = selectedOEs;
        let currentElementIndex = selectedOEs.findIndex(item => item?.Id == value?.Id)

        if (!value || value === "") {
            selectedOEsArray = [];
        }
        else if (currentElementIndex >= 0) {
            selectedOEsArray.splice(currentElementIndex, 1);
        }
        else {
            selectedOEsArray.push(value);
        }
        setSelectedOEs([...selectedOEsArray]);
    }


    const getTitleSendOEButton = (oeRequest) => {
        if (oeRequest?.OERequestStatusID === 4) {
            return <div>This button is disabled because OE request is Blocked</div>;
        } else if (oeRequest?.RequestTypeName === "One Time") {
            return <div>Please add a comment if the OE data is ready</div>;
        } else if (oeRequest?.OERequestStatusID === 3) {
            return <div>This button is disabled because OE request is Cancelled</div>;
        } else if (Moment(todayDate).format('YYYY-MM-DD') >= oeRequest?.PlanYearStartDate) {
            return <div>Plan Year Start Date has passed, updates will go through the weekly files</div>;
        } else if ((new Date(oeRequest?.PlanYearStartDate).getTime() - new Date(Moment(todayDate).format('YYYY-MM-DD')).getTime()) / (1000 * 3600 * 24) >= 45) {
            return <div>The earliest for an OE file to be sent is 45 days before the PYSD to capture terminations as expected</div>;
        }
        else {
            return <div>OE Data is Ready</div>;
        }
    }

    const handleDownloadOEFile = (OERequestID) => {
        openLoader();
        APIs.downloadOEFile(OERequestID).then(r => {
            window.open(r?.url)
        }).catch(error => {
            FailureModal(dialog, {
                title: 'Failure',
                body: 'Failed To Download OE File',
            })
        }).finally(() => closeLoader())
    }

    const isIsovedSuperAdminOrSuperAdmin = () => {
        //attached action ID to isolvedSuperAdmin
        return userActions?.includes("57") || userActions?.includes("20");
    }

    const CheckAllRowsAtTableHeader = () => {
        return (<div>
            {oERequestsList?.length > 0 &&
                <Checkbox
                    style={{ cursor: "pointer" }}
                    id={`oes-select-all`}
                    value={oERequestsList?.filter(s => s?.SendOEFileDone !== 1 &&
                        s?.OERequestStatusID !== 3 &&
                        s?.RequestTypeName !== "One Time" &&
                        s?.OERequestStatusID !== 4 &&
                        Moment(todayDate).format('YYYY-MM-DD') < s?.PlanYearStartDate &&
                        (new Date(s?.PlanYearStartDate).getTime() - new Date(Moment(todayDate).format('YYYY-MM-DD')).getTime()) / (1000 * 3600 * 24) < 45)}
                    icon={<CheckBoxOutlineBlankIcon />}
                    checked={oERequestsList?.filter(s => s?.SendOEFileDone !== 1 &&
                        s?.OERequestStatusID !== 3 &&
                        s?.RequestTypeName !== "One Time" &&
                        s?.OERequestStatusID !== 4 &&
                        Moment(todayDate).format('YYYY-MM-DD') < s?.PlanYearStartDate &&
                        (new Date(s?.PlanYearStartDate).getTime() - new Date(Moment(todayDate).format('YYYY-MM-DD')).getTime()) / (1000 * 3600 * 24) < 45).length > 0 && selectedOEs.length === oERequestsList?.filter(s => s?.SendOEFileDone !== 1 &&
                            s?.OERequestStatusID !== 3 &&
                            s?.RequestTypeName !== "One Time" &&
                            s?.OERequestStatusID !== 4 &&
                            Moment(todayDate).format('YYYY-MM-DD') < s?.PlanYearStartDate &&
                            (new Date(s?.PlanYearStartDate).getTime() - new Date(Moment(todayDate).format('YYYY-MM-DD')).getTime()) / (1000 * 3600 * 24) < 45).length
                    }

                    onClick={handleSelectAllOEsClick(oERequestsList?.filter(s => s?.SendOEFileDone !== 1 &&
                        s?.OERequestStatusID !== 3 &&
                        s?.RequestTypeName !== "One Time" &&
                        s?.OERequestStatusID !== 4 &&
                        Moment(todayDate).format('YYYY-MM-DD') < s?.PlanYearStartDate &&
                        (new Date(s?.PlanYearStartDate).getTime() - new Date(Moment(todayDate).format('YYYY-MM-DD')).getTime()) / (1000 * 3600 * 24) < 45))}
                    checkedIcon={<DoneOutlineIcon />}
                    color="default"
                    className='mx-auto'

                />}


        </div>)
    }

    // ========================================================NEW UI
    // Generic Table
    const handleSort = (column, sortDirection, type) => {
        // setSortOrder(sortDirection?.toUpperCase());

        if (type === "quick") {
            onSortFieldFilter(column?.sortingkey)
            onSortOrderFilter(sortDirection?.toUpperCase());

        } else if (type === 'adv') {
            onSortFieldAdvFilter(column?.sortingkey)
            onSortOrderAdvFilter(sortDirection?.toUpperCase());
        }
    };

    const handleDatePopup = (o) => {
        (o?.PlanYearStartDate) ?
            SuccessModal(dialog, {
                title: '1st Production file date after OE',
                body: Moment(o?.PlanYearStartDate).format('MM/DD/YYYY')
            }) :
            FailureModal(dialog, {
                title: 'No Date',
                body: 'Date will be available once the OE file is sent'
            })

    }
    const columnsArray = [
        {
            name: 'OE Request ID',
            width: "70px",
            sortable: true,
            sortingkey: 'Id',
            id: 'Id',
            selector: o => <a href={`/OERequests/ViewConnectivityRequest/${o?.ConnectivityRequestID}/ViewOERequest/${o?.Id}`}>{o?.Id}</a>
        },
        {
            name: 'CR ID',
            width: "70px",
            sortable: true,
            sortingkey: 'ConnectivityRequestID',
            id: 'ConnectivityRequestID',
            selector: o => <a href={`/ConnectivityRequests/ViewConnectivityRequest/${o?.ConnectivityRequestID}`}>{o?.ConnectivityRequestID}</a>
        },
        {
            name: 'Client Name',
            sortable: true,
            sortingkey: 'GroupName',
            id: 'GroupName',
            selector: o => <a href={`/GroupRequests/ViewGroupRequest/${o?.GroupRequestID}`}>{o?.PeoName ? o?.PeoName : o?.GroupName}</a>
        },
        {
            name: 'Carrier Name',
            sortable: true,
            sortingkey: 'CarrierName',
            id: 'CarrierName',
            selector: o => <a href={`/ConnectivityRequests/ViewConnectivityRequest/${o.ConnectivityRequestID}`}>{o?.CarrierName === "Third Party" ? `${o.CarrierName} (${o.ThirdPartyName})` : o.CarrierName}</a>
        },
        {
            name: 'Updated Group Structure',
            // width: "5.25%",
            width: "70px",
            sortable: true,
            sortingkey: 'UpdatedGroupStructure',
            id: 'UpdatedGroupStructure',
            selector: o => o?.UpdatedGroupStructure === 1 ? "Yes" : "No"
        },
        {
            name: 'Plan Year Start Date',
            width: '100px',
            sortable: true,
            sortingkey: 'PlanYearStartDate',
            id: 'PlanYearStartDate',
            selector: o => Moment(o?.PlanYearStartDate).format('MM/DD/YYYY')
        },
        {
            name: 'Request Type',
            width: '70px',
            sortable: true,
            sortingkey: 'RequestTypeName',
            id: 'RequestTypeName',
            selector: c => c?.RequestTypeName
        },
        {
            name: "Client's Expected Data Ready Date",
            // width: "7%",
            width: '100px',
            sortable: true,
            sortingkey: 'ClientDataExpectedDate',
            id: 'ClientDataExpectedDate',
            selector: o => Moment(o?.ClientDataExpectedDate).format('MM/DD/YYYY')
        },
        {
            name: 'Assigned To',
            // width: "6.25%",
            sortable: true,
            sortingkey: 'AssignedTo',
            id: 'AssignedTo',
            selector: o => o?.AssignedTo !== null ? o?.AssignedTo : ""
        },
        {
            name: 'isolved Contact',
            // width: "6.25%",
            sortable: true,
            sortingkey: 'IsolvedContact',
            id: 'IsolvedContact',
            selector: o => o?.IsolvedContact !== null ? o?.IsolvedContact : ""
        },
        {
            name: 'Draft',
            // width: "4.53%",
            width: "50px",
            sortable: true,
            sortingkey: 'IsDraftOERequest',
            id: 'IsDraftOERequest',
            selector: o => o?.IsDraftOERequest === 1 ? "Yes" : "No"
        },
        {
            name: 'Status',
            // width: "6.25%",
            sortable: true,
            sortingkey: 'OERequestStatusName',
            id: 'OERequestStatusName',
            selector: o => o?.OERequestStatusName
        },
        {
            name: 'Phase',
            // width: "6.25%",
            sortable: true,
            sortingkey: 'OERequestPhaseName',
            id: 'OERequestPhaseName',
            selector: o => o?.OERequestPhaseName
        },
        {
            name: 'OE File Transmission Date',
            // width: "7%",
            sortable: true,
            sortingkey: 'OEFileSubmissionDate',
            id: 'OEFileSubmissionDate',
            selector: o => (<div>
                {o?.OEFileSubmissionDate !== null && o?.OEFileSubmissionDate !== "0000-00-00" ? Moment(o?.OEFileSubmissionDate).format('MM/DD/YYYY') : ""}
                <div className="mt-1">
                    {(o?.OERequestPhaseName !== "Completed" && o?.OERequestPhaseName !== "Get Carrier Confirmation") ? 'N/A' :
                        o?.OEFileSubmissionDate && o?.PlanYearStartDate && o?.SendOEFileDone == 1 && o?.SendOEFileDate &&
                            (Moment(o?.OEFileSubmissionDate).format('YYYY-MM-DD') < Moment(o?.PlanYearStartDate).format('YYYY-MM-DD')) &&
                            (Moment(o?.OEFileSubmissionDate).format('YYYY-MM-DD') >= Moment(o?.SendOEFileDate).format('YYYY-MM-DD')) ? <>
                            <CustomTooltip TransitionComponent={Zoom} title="Download OE File">
                                <button className="sendOEButtonDashboard" onClick={() => handleDownloadOEFile(o?.Id)}>
                                    <svg width="20" height="20" viewBox="0 0 20 20" >
                                        <use xlinkHref={`${downloadIconSVG}#downloadIcon`}></use>
                                    </svg>
                                </button>
                            </CustomTooltip>
                        </> : ''
                    }
                </div>
            </div>)
        },
        {
            name: 'Created Date',
            // width: "7.0%",
            sortable: true,
            sortingkey: 'Created',
            id: 'Created',
            selector: o => o?.Created !== null ? Moment(o?.Created).format('MM/DD/YYYY') : ""
        },
        {
            name: 'Actions',
            // width: "7.3%",
            width: '80px',
            sortable: false,
            id: 'actions',
            selector: o =>
            (<>
                {(userActions?.includes("57") ||
                    userActions?.includes("20")) ? (
                    <CustomTooltip TransitionComponent={Zoom} title={o?.SendOEFileDone === 1 ? `OE Data is Ready` : getTitleSendOEButton(o)}>
                        <span>
                            <button disabled={
                                selectedOEs.length !== 0 ||
                                o?.SendOEFileDone === 1 ||
                                o?.RequestTypeName === "One Time" ||
                                o?.OERequestStatusID === 3 ||
                                o?.OERequestStatusID === 4 ||
                                Moment(todayDate).format('YYYY-MM-DD') >= o?.PlanYearStartDate ||
                                (new Date(o?.PlanYearStartDate).getTime() - new Date(Moment(todayDate).format('YYYY-MM-DD')).getTime()) / (1000 * 3600 * 24) >= 45
                            }
                                style={(selectedOEs.length !== 0 || o?.SendOEFileDone === 1 || o?.OERequestStatusID === 3 || o?.OERequestStatusID === 4 || o?.RequestTypeName === "One Time" || Moment(todayDate).format('YYYY-MM-DD') >= o?.PlanYearStartDate) ? { pointerEvents: "none", } : {}} className="sendOEButtonDashboard" onClick={(e) => {
                                    openLoader();
                                    APIs.sendOEFile({ OERequestIDs: [o.Id] }).then(r => {
                                        if (r.success === "1") {
                                            openLoader();
                                            APIs.getOERequestDetails(o.Id).then(r => {
                                                openLoader();
                                                e.preventDefault();
                                                let editOEBody = {
                                                    OERequestID: r.data.Id,
                                                    PlanYearStartDate: r.data.PlanYearStartDate,
                                                    ClientDataExpectedDate: r.data.ClientDataExpectedDate,
                                                    OEType: r.data.OEType,
                                                    OEChanges: r.data.OEChanges,
                                                    ISolvedDataChanges: r.data.ISolvedDataChanges,
                                                    UpdatedGroupStructure: r.data.UpdatedGroupStructure,
                                                    IsolvedContact: r.data.IsolvedContactCognitoID,
                                                    OERequestStatusID: r.data.OERequestStatusID,
                                                    OERequestPhaseID: 4,
                                                    AssignedTo: r.data.AssignedToCognitoID,
                                                    OEFileSubmissionDate: r.data.OEFileSubmissionDate,
                                                    NoAttachmentUpdate: true,
                                                    IsDraftOERequest: r.data.IsDraftOERequest,
                                                    CanResumeProducionPYSD: r.data?.CanResumeProducionPYSD ?? 0
                                                }
                                                // Api for edit OE Request 
                                                APIs.editOERequest(editOEBody).then(r => window.location.reload())
                                            })
                                        } else {
                                            closeLoader();
                                            FailureModal(dialog, {
                                                title: 'Failure',
                                                body: r.msg,
                                            })
                                        }
                                    })
                                }}>
                                {o?.SendOEFileDone === 1 ?
                                    <svg width="19" height="13" viewBox="0 0 19 13" >
                                        <use xlinkHref={`${dataReadyIconSVG}#dataReadyIcon`}></use>
                                    </svg> :
                                    o?.OERequestStatusID === 3 ||
                                        o?.RequestTypeName === "One Time" ||
                                        o?.OERequestStatusID === 4 ||
                                        Moment(todayDate).format('YYYY-MM-DD') >= o?.PlanYearStartDate ||
                                        (new Date(o?.PlanYearStartDate).getTime() - new Date(Moment(todayDate).format('YYYY-MM-DD')).getTime()) / (1000 * 3600 * 24) >= 45 ?
                                        <svg width="15" height="15" viewBox="0 0 15 15" >
                                            <use xlinkHref={`${oeDataReadyDisabled}#oeDataReadyDisabled`}></use>
                                        </svg> :
                                        <svg width="15" height="15" viewBox="0 0 15 15" >
                                            <use xlinkHref={`${oeDataReadyEnabled}#oeDataReadyEnabled`}></use>
                                        </svg>
                                }
                            </button>
                        </span>
                    </CustomTooltip>
                ) : (
                    <CustomTooltip TransitionComponent={Zoom} title={`OE Data is Ready`}>
                        {o?.SendOEFileDone === 1 ?
                            <svg width="19" height="13" viewBox="0 0 19 13" >
                                <use xlinkHref={`${dataReadyIconSVG}#dataReadyIcon`}></use>
                            </svg> :
                            o?.OERequestStatusID === 3 ||
                                o?.RequestTypeName === "One Time" ||
                                o?.OERequestStatusID === 4 ||
                                Moment(todayDate).format('YYYY-MM-DD') >= o?.PlanYearStartDate ||
                                (new Date(o?.PlanYearStartDate).getTime() - new Date(Moment(todayDate).format('YYYY-MM-DD')).getTime()) / (1000 * 3600 * 24) >= 45 ?
                                <svg width="15" height="15" viewBox="0 0 15 15" >
                                    <use xlinkHref={`${oeDataReadyDisabled}#oeDataReadyDisabled`}></use>
                                </svg> :
                                <svg width="15" height="15" viewBox="0 0 15 15" >
                                    <use xlinkHref={`${oeDataReadyEnabled}#oeDataReadyEnabled`}></use>
                                </svg>
                        }
                    </CustomTooltip>
                    // not have draft flag and request type is (regular)  
                )} {o?.IsDraftOERequest === 0 && o?.RequestTypeId === 1 && <CustomTooltip TransitionComponent={Zoom} title={`1st Production file date after OE`}>
                    <button className="sendOEButtonDashboard" onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        handleDatePopup(o)
                    }}>
                        <svg width="18" height="18" viewBox="0 0 18 18" >
                            <use xlinkHref={`${schedulerIconSVG}#schedulerIcon`}></use>
                        </svg>
                    </button>
                </CustomTooltip>}
            </>
            )
        },
    ];


    const pushToColumnsBasedOnUserAction = () => {

        const columnsToAdd = [
            {
                name: CheckAllRowsAtTableHeader(),
                sortable: false,
                width: '80px',
                centerText: true,
                doesNotHaveToolTip: true,
                // sortingkey: 'MigrationGroupRequest',
                selector: o => <div
                >
                    <CustomTooltip TransitionComponent={Zoom} title={oERequestsList?.SendOEFileDone === 1 ? `OE Data is Ready` : getTitleSendOEButton()}>
                        <Checkbox
                            disabled={
                                // !userActions?.includes("57") ||
                                // !userActions?.includes("20") ||
                                o?.SendOEFileDone === 1 ||
                                o?.RequestTypeName === "One Time" ||
                                o?.OERequestStatusID === 3 ||
                                o?.OERequestStatusID === 4 ||
                                Moment(todayDate).format('YYYY-MM-DD') >= o?.PlanYearStartDate ||
                                (new Date(o?.PlanYearStartDate).getTime() - new Date(Moment(todayDate).format('YYYY-MM-DD')).getTime()) / (1000 * 3600 * 24) >= 45
                            }
                            checkedIcon={<DoneOutlineIcon />}
                            value={o}
                            icon={<CheckBoxOutlineBlankIcon />}
                            checked={selectedOEs && selectedOEs.findIndex(item => item?.Id == o?.Id) >= 0}
                            // onChange={handleOEsSelectionChange(o)}
                            onClick={handleOEsSelectionClick(o)}
                            color="default"
                        />
                    </CustomTooltip>
                </div>
            },
        ];
        // Create a copy of the base columns array
        let finalColumnsArray = [...columnsArray];

        if (isIsovedSuperAdminOrSuperAdmin()) {
            finalColumnsArray.unshift(...columnsToAdd);
        }
        return finalColumnsArray;
    };

    const FilteredAndAddedIsExpand = () => {
        if (!Array.isArray(oERequestsList)) {
            return [];
        }

        return oERequestsList
    }


    return (
        <div id="oe-request-table" ref={tableRef}>
            <div className="max-w-100 mt-3">
                <GenericTable
                    columns={[...pushToColumnsBasedOnUserAction()]}
                    data={FilteredAndAddedIsExpand()}
                    onSort={(column, sortDirection) => column?.sortable ? handleSort(column, sortDirection, quickFiltersClicked ? 'quick' : 'adv') : () => { }}
                    isStickeyHeader={true}
                />
            </div>

            <Pagination
                stateLimit={true}
                pageSize={quickFiltersClicked ? pageSize : advancedFiltersClicked ? pageSizeAdv : ""}
                page={quickFiltersClicked ? selectedPage : advancedFiltersClicked ? selectedPageAdv : ""}
                onChange={(t, v) => {
                    if (quickFiltersClicked) {
                        if (t === 1) setPageSize(v);
                        else if (t === 2) setSelectedPage(v);
                    } else if (advancedFiltersClicked) {
                        if (t === 1) onSetPageSizeAdv(v);
                        else if (t === 2) onSetSelectedPageAdv(v);
                    }

                }}
                count={totalCount}
            />

        </div >
    )
}

export default OERequestsTable
